import { actions } from 'xstate'
import { useMachine } from '@xstate/vue'
import {
  createInjectionState,
  reactiveComputed,
  useTimeoutPoll,
} from '@vueuse/core'
import { snakeCase } from 'lodash'
import { machine as b2cBuyMachine } from './b2cBuyMachine'
import { formatTronErrorMessage } from '~/utils/tron-v2/tronError'
import { tron } from '~/utils/tron-v2'
import { extractContentInBrackets, sleep } from '~/utils'

/**
 * 1. 查询订单状态未完成
 * 2. 弹窗状态判断更新
 */
export const [useB2cBuyMachineProvide, useB2cBuyMachine] = createInjectionState(
  () => {
    const { isConnected, account } = tronComp.useAccountCurrent()
    const { config } = useAppState()
    const paymentResult = ref<Api.GetData<typeof apis.orderQuery> | null>(null)

    const ttpayPaymentDownOrderResult = ref(null)
    const ttpayPaymentStatus = ref(0)

    const machine = useMachine(b2cBuyMachine, {
      actions: {
        handleError(ctx, ev) {
          if (ev.data instanceof Error) {
            if (ev.data.message.includes('balance is not sufficient') || ev.data.message.includes('Tapos check error') || ev.data.message.includes('Account resource insufficient error'))
              aMessage.error($t('tm41OUsNwS4B7IkB1jey'))
            else if (ev.data.message.includes('does not exist'))
              aMessage.error(`${extractContentInBrackets(ev.data.message)}  ${$t('AUdgXSOOMymyhIWK9Mg1A')}`)
            else if (ev.data.message !== '')
              aMessage.error(ev.data.message)
          }
        },

        setInfo: actions.assign((ctx, ev) => {
          if (ev.type === 'PAY') {
            return {
              ...ev,
            }
          }

          return {}
        }),

        setPaymentResult: actions.assign((ctx, ev) => {
          return { paymentResult: ev.data }
        }),

        setTtpayDownPaymentResult: actions.assign((ctx, ev: any) => {
          ttpayPaymentDownOrderResult.value = ev.data.orderResult
          return { ttpayPaymentDownOrderResult: ev.data.orderResult }
        }),

        doneConnectWallet: actions.assign((ctx, ev) => {
          return {
            paymentAddress: account.address,
          }
        }),

        setTtpayPaymentResult: (ctx, ev: any) => {
          ttpayPaymentStatus.value = ev.ttpayPaymentStatus
        },
      },
      services: {
        // dapp
        connectTronlink: async (ctx, ev) => {
          try {
            await globalModal.ConnectWallet?.open()
          }
          catch (e) {
            throw new Error($t('s-g1lDjln7jTc4kzSCohl'))
          }
        },
        precreateTronlinkOrder: async (ctx, ev) => {
          await apis.orderPreCreateB2C({
            requestBody: {
              pay_symbol: 'TRX',
              auto_to_market: 1,
              receive_address: ctx.receiveAddress,
              rent_duration: ctx.rentDuration,
              rent_time_unit: ctx.rentDurUnit,
              resource_type: 1,
              resource_value: ctx.resourceValue,
            },
          })
        },
        signTronlink: async (ctx, ev) => {
          try {
            const tx = await tron.transfer({
              chainId: account.chainId,
              fromAddress: ctx.paymentAddress,
              toAddress: config?.value?.wallet.payment_b2c_address!,
              symbol: 'TRX',
              amount: String(ctx.amount),
            })
            return tx
          }
          catch (e) {
            const msg = formatTronErrorMessage(e)
            aMessage.error(msg)
            // eslint-disable-next-line unicorn/error-message
            throw new Error('')
          }
        },
        createTronlinkOrder: async (ctx, ev) => {
          try {
            const res = await apis.orderCreateB2C({
              requestBody: {
                pay_symbol: 'TRX',
                auto_to_market: 1,
                receive_address: ctx.receiveAddress,
                rent_duration: ctx.rentDuration,
                rent_time_unit: ctx.rentDurUnit,
                resource_type: 1,
                resource_value: ctx.resourceValue,
                wait_second: ctx.waitSecond,
                signed_txn: ev.data as any,
              },
            }, { errorMessageMode: 'none' })

            return {
              orderNo: res.order_no,
              txId: res.pay_tx_id,
              receiveAddress: res.receive_address,
            }
          }
          catch (e) {
            throw new Error(e)
          }
        },
        queryTronlinkOrder: async (ctx, ev) => {
          // 轮询订单
          let second = 0
          const data = ev.data as any
          do {
            try {
              await sleep(2000)
              second++
              const res = await apis.orderQuery(
                {
                  order_no: data.orderNo,
                  tx_id: data.txId,
                  trx_address: data.trx_address,
                },
                { errorMessageMode: 'none' },
              )
              if (res)
                return res
            }
            catch (error: any) {
              if (error.code !== 17019)
                throw new Error(error)
            }
          } while (second <= 10)
        },

        // ttpay
        precreateTtpayOrder: async (ctx, ev) => {
          return await apis.orderPreCreateB2C({
            requestBody: {
              auto_to_market: ctx.autoToMarket,
              order_no: '',
              order_type: 1,
              pay_address: ctx.payAddress,
              pay_amount: ctx.amount,
              pay_symbol: 'TRX',
              pay_tx_id: '',
              receive_address: ctx.receiveAddress,
              rent_duration: ctx.rentDuration,
              rent_time_unit: ctx.rentDurUnit,
              resource_value: ctx.resourceValue,
              resource_type: ctx.resourceType,

            },
          }, { errorMessageMode: 'none' })
        },
        createTtpayOrder: async (ctx, ev) => {
          const res = await apis.ttpayOrderB2C({
            requestBody: {
              auto_to_market: ctx.autoToMarket,
              order_no: '',
              order_type: 1,
              pay_address: ctx.payAddress,
              pay_amount: ctx.amount,
              pay_symbol: 'TRX',
              pay_tx_id: '',
              receive_address: ctx.receiveAddress,
              rent_duration: ctx.rentDuration,
              rent_time_unit: ctx.rentDurUnit,
              resource_value: ctx.resourceValue,
              resource_type: ctx.resourceType,
              wait_second: ctx.waitSecond,
            },
          })
          return { orderResult: res }
        },
        queryTtpayOrder: (ctx, ev) => (callback, onReceive) => {
          const { orderResult: result } = ev.data as any
          const startTime = Date.now()
          const limtTime = 10 * 60 * 1000
          const { resume, pause } = useTimeoutPoll(async () => {
            if (Date.now() - startTime > limtTime) {
              pause()
              callback({
                type: 'ASSERT_SUCCESS',
                ttpayPaymentStatus: 17025,
              })
              return
            }

            // 接口请求和结果处理
            const res = await apis.orderQuery(
              { order_no: unref(result.out_trade_no) },
              {
                errorMessageMode: 'none',
                resultType: 'api',
                checkCode: false,
              },
            )

            if ([0, 11003].includes(res.code)) {
              callback({
                type: 'ASSERT_SUCCESS',
                ttpayPaymentStatus: res.data?.status ?? res.code,
              })
            }

            if (res.code !== 17019) {
              callback({
                type: 'ASSERT_FAILL',
                ttpayPaymentStatus: res.code,
              })
            }
          }, 2000)

          resume()
          return () => pause()
        },

        // balance
        createBalanceOrder: async (ctx, event) => {
          try {
            const data:
            | Api.GetParam<typeof apis.apiOrderCreateOrderByBalance>['requestBody']
            | { [key: string]: string | number } = {}

            for (const key in ctx)
              data[snakeCase(key)] = ctx[key]

            return await apis.apiOrderCreateOrderByBalance({ requestBody: { ...data, pay_amount: data.amount } })
          }
          catch (e) {
            throw new Error(e)
          }
        },
      },
      guards: {
        isTronlink: (ctx, ev) => {
          return ev.paymentMethod === 'dapp'
        },
        isTtpay: (ctx, ev) => {
          return ev.paymentMethod === 'ttpay'
        },
        isBalance: (ctx, ev) => {
          return ev.paymentMethod === 'balance'
        },
        isTronlinkConnected: () => {
          return isConnected.value
        },
      },
    })
    const states = reactiveComputed(() => {
      const s = machine.state.value
      const confirmInfoOpen = s.matches('confirm')
      const paymentLoadingOpen
        = s.matches('tronlink.order-precreating')
        || s.matches('tronlink.signing')
        || s.matches('tronlink.order-creating')
        || s.matches('tronlink.order-querying')
        || s.matches('ttpay.order-precreating')
        || s.matches('ttpay.order-creating')
        || s.matches('balance.balance-create-order')

      const paymentOpen = s.matches('tronlink.continue')

      const paymentSuccessfulOpen = s.matches('payment-successful')
      const ttpayPaymentSuccessful = s.matches('ttpay-payment-successful')
      const ttpayPaymentWaitingOpen = s.matches('ttpay.order-querying')
      const isBalanceResults = s.matches('balance.order-results')

      return {
        confirmInfoOpen,
        paymentLoadingOpen,
        paymentOpen,
        paymentSuccessfulOpen,
        ttpayPaymentSuccessful,
        ttpayPaymentWaitingOpen,
        isBalanceResults,
      }
    })

    watch(machine.state, ({ event }: { event: any }) => {
      if (event.type === 'done.invoke.queryTronlinkOrder')
        paymentResult.value = event.data as any
    })

    return {
      machine,
      states,
      paymentResult,
      ttpayPaymentDownOrderResult,
      ttpayPaymentStatus,
    }
  },
)

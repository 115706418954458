<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import RentalAbout from './components/RentalAbout.vue'
import RentalOperation from './components/RentalOperation.vue'
import RentLog from './components/RentLog.vue'
import AutoLog from './components/AutoLog.vue'
import { useB2cBuyMachineProvide } from './machine/useB2cBuyMachine'
import WalletPlate from '~/pages-components/c2c/components/WalletPlate.vue'
import { useActivityProvider } from '~/composables/useActivity'
import { ActivityMobile, ActivityModels } from '~/components/activity'

useB2cBuyMachineProvide()
useActivityProvider()
const activeKey = ref('rent')
const autoPopup = useEventBus<string>('AUTO-POPUP')

function listener() {
  activeKey.value = 'auto'
}
autoPopup.on(listener)
</script>

<template>
  <div class="b2c-container">
    <div class="b2c-content page-content-width">
      <div class="b2c-form">
        <RentalAbout />
        <div>
          <WalletPlate class="mb-4 tabletl:hidden" />
          <ActivityMobile class="tabletl:hidden !mb-4" />
          <RentalOperation />
        </div>
      </div>
      <div class="trad-order">
        <ATabs
          v-model:activeKey="activeKey"
          class="ant-cover__basic-tabs z-tabs mt-50px mb-100px lt-tablet:mb-0"
          destroyInactiveTabPane
        >
          <ATabPane key="rent" :tab="$t('ng8Dze0xWjT4_Aw7LIBiO')">
            <RentLog class="content" :type="activeKey" />
          </ATabPane>

          <ATabPane key="auto" :tab="$t('iwVUwLoYpcblsyws4eQHp')">
            <AutoLog class="content" :type="activeKey" />
          </ATabPane>
        </ATabs>
      </div>
    </div>
    <Announcement pos="6" />
    <!-- 活动弹窗  -->
    <ActivityModels v-if="$bp.ltTabletl" />
  </div>
</template>

<style lang="scss" scoped>
@media bp-lt-tabletl {
  .stc-display {
    display: none;
  }

  .b2c-container {
    .b2c-content {
      margin: 16px 16px 20px;

      .b2c-form {
        display: block;
        width: 100%;
        max-width: 1440px;
        padding: 0;
      }
    }

    .api {
      padding-right: 0;
      padding-left: 0;
      margin-top: 0;
      background-color: transparent;
    }
  }

  :deep(.api) {
    .title {
      color: #000;
    }
  }
}

@media bp-lt-laptop {
  .b2c-content {
    --uno: 'justify-center flex-col';
  }
}

.b2c-content {
  --uno: 'page-section';

  position: relative !important;
  z-index: 4;
  width: calc(100% - 30px);
  max-width: 1440px;
  margin: auto;

  .b2c-form {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 70px;
  }

  .trad-order{
    padding: 10px 20px 30px;
    margin: 50px 0 30px;
    background: var(--app-gradient-top-light-d);
    border-radius: 30px;

    @media bp-lt-tablet {
      padding: 10px 15px;
      margin: 16px 0 30px;
      border-radius: 10px;
    }

    .content {
      margin: 0 -20px;

      @media bp-lt-tablet {
        margin: 0 -15px;
      }
    }
  }
}

.api {
  margin: 30px auto;
}

:deep(.api) {
  .title {
    color: #fff;
  }
}
</style>
